<template>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <path
            fill="#FFD05D"
            d="M2.972 16.948C2.972 7.588 10.562 0 19.92 0 29.281 0 36.87 7.588 36.87 16.948S29.28 33.895 19.92 33.895c-9.358 0-16.948-7.588-16.948-16.947"
            transform="translate(-110 -1699) translate(26 1695) translate(84.108 4.108)"
          />
          <path
            fill="#FDC453"
            d="M28.788 2.483c1.686 2.634 2.667 5.767 2.667 9.128 0 9.36-7.59 16.948-16.949 16.948-3.25 0-6.287-.916-8.868-2.503 3.013 4.702 8.284 7.82 14.282 7.82 9.361 0 16.949-7.589 16.949-16.948 0-6.11-3.233-11.463-8.08-14.445"
            transform="translate(-110 -1699) translate(26 1695) translate(84.108 4.108)"
          />
          <path
            fill="#FFD77F"
            d="M5.277 18.735c0-8.69 7.044-15.734 15.735-15.734 5.078 0 9.596 2.407 12.473 6.143-2.718-4.717-7.81-7.893-13.644-7.893-8.691 0-15.736 7.045-15.736 15.735 0 3.61 1.217 6.936 3.263 9.592-1.33-2.309-2.091-4.987-2.091-7.843"
            transform="translate(-110 -1699) translate(26 1695) translate(84.108 4.108)"
          />
          <path
            fill="#303030"
            d="M10.78 17.221c-.215 0-.425-.095-.568-.277-.243-.313-.187-.764.125-1.008l2.445-1.904-2.527-1.486c-.342-.201-.457-.642-.254-.984.2-.342.64-.456.982-.255l3.438 2.022c.205.12.338.335.354.573.014.237-.088.467-.277.613L11.22 17.07c-.132.103-.287.152-.44.152M29.063 17.221c-.155 0-.31-.05-.442-.152l-3.278-2.554c-.187-.146-.29-.376-.275-.613.014-.238.147-.452.352-.573l3.438-2.022c.342-.2.781-.087.984.255.2.342.086.783-.256.984l-2.526 1.486 2.444 1.904c.312.244.37.695.125 1.008-.14.182-.354.277-.566.277"
            transform="translate(-110 -1699) translate(26 1695) translate(84.108 4.108)"
          />
          <path
            fill="#7CC4E8"
            d="M30.63 16.456c-1.255 7.523.95 10.031.95 10.031 1.296 2.097 4.046 2.747 6.142 1.452 2.097-1.295 2.748-4.046 1.453-6.143 0 0-1.256-3.094-8.546-5.34"
            transform="translate(-110 -1699) translate(26 1695) translate(84.108 4.108)"
          />
          <path
            fill="#62BBE8"
            d="M34.123 28.425c1.171.342 2.48.206 3.599-.486 2.097-1.295 2.748-4.046 1.453-6.143 0 0-.53-1.3-2.887-2.826 2.285 5.389-.254 8.194-2.165 9.455"
            transform="translate(-110 -1699) translate(26 1695) translate(84.108 4.108)"
          />
          <path
            fill="#7CC4E8"
            d="M9.029 16.347c-7.234 2.418-8.417 5.54-8.417 5.54-1.245 2.128-.53 4.862 1.597 6.108 2.127 1.245 4.861.53 6.108-1.597 0 0 2.143-2.56.712-10.051"
            transform="translate(-110 -1699) translate(26 1695) translate(84.108 4.108)"
          />
          <path
            fill="#62BBE8"
            d="M.093 25.042c.247 1.196.98 2.287 2.116 2.953 2.127 1.245 4.861.53 6.108-1.597 0 0 .898-1.078 1.143-3.875-3.688 4.544-7.359 3.617-9.367 2.52"
            transform="translate(-110 -1699) translate(26 1695) translate(84.108 4.108)"
          />
          <path
            fill="#695342"
            d="M10.212 19.193c.55 4.876 4.686 8.665 9.708 8.665 5.023 0 9.159-3.79 9.71-8.665H10.211"
            transform="translate(-110 -1699) translate(26 1695) translate(84.108 4.108)"
          />
          <path
            fill="#F06669"
            d="M13.214 25.19c1.75 1.653 4.11 2.668 6.706 2.668 2.486 0 4.754-.929 6.478-2.457l-.09-.21c-6.519-3.18-13.005-.045-13.094-.002"
            transform="translate(-110 -1699) translate(26 1695) translate(84.108 4.108)"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
